<template>
  <div>
    <h2>Neutralisation d'un acide fort par une base forte : Données + Calculs</h2>
    <v-form>
      <!-- Data Table -->
      <h3 class="pt-6 pb-4">Tableau de données</h3>
      <v-textarea
        v-model="inputs.data_table_caption"
        label="Légende"
        outlined
        rows="1"
        class="mb-n3"
        placeholder="Rédigez une légende pour le tableau des données ici."
      />
      <v-simple-table>
        <thead>
          <tr>
            <td>&nbsp;</td>
            <td style="font-weight: bold; text-align: center">Unités</td>
            <td style="font-weight: bold; text-align: center">Essai 1</td>
            <td style="font-weight: bold; text-align: center">Essai 1</td>
            <td style="font-weight: bold; text-align: center">Essai 2</td>
            <td style="font-weight: bold; text-align: center">Essai 2</td>
          </tr>
        </thead>
        <tr>
          <th style="text-align: left">Acid</th>
          <td>&nbsp;</td>
          <template v-for="i in 2">
            <td v-for="j in 2" :key="`known-${i}-${j}`" style="text-align: center">
              <stemble-latex :content="`$\\ce{${j % 2 ? 'HCl' : 'HNO3'}}$`" />
            </td>
          </template>
        </tr>
        <tr v-for="{label, key, units: rowUnits} in dataTableRows" :key="key">
          <th style="text-align: left">
            <stemble-latex :content="label" />
          </th>
          <td>
            <v-select
              v-if="!!rowUnits"
              v-model="inputs[`${key}_units`]"
              :items="units"
              item-value="key"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex :content="item.label" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.label" />
              </template>
            </v-select>
            <span v-else>&nbsp;</span>
          </td>
          <template v-for="i in 2">
            <td v-for="j in 2" :key="`known-${key}-${i}-${j}`">
              <calculation-input
                v-model="inputs[`trial${i}_${j % 2 ? 'hcl' : 'hno3'}_${key}`]"
                class="centered-input"
              />
            </td>
          </template>
        </tr>
      </v-simple-table>

      <!-- Data Upload -->
      <h3 class="pt-8 pb-3">Téléversement des graphiques</h3>

      <p class="mb-2">
        Dans cette expérience, vous recueillez des données de température en fonction du temps.
        Tracez vos données de température en fonction du temps (pour compléter votre analyse, vous
        devez tracer les données pour les deux essais, mais nous vous demanderons seulement de
        téléverser le graphique pour l'un des deux essais).
      </p>

      <p class="mb-2">
        Assurez-vous que votre graphique ait un titre approprié et une légende de figure, et que
        vous placiez les données correctes sur les axes correspondants. Étiquetez vos axes et
        indiquez les unités là où cela est nécessaire. Référez-vous à la Figure 2.1 dans la section
        Graphiques du protocole expérimental et tracez la ligne verticale au moment du mélange.
        Ensuite, extrapolez jusqu'au moment du mélange à partir de votre température finale
        stabilisée (elle peut être horizontale ou inclinée en fonction de vos données).
      </p>

      <p class="mb-2">
        Vous avez effectué 2 essais pour chacun des 2 acides. Téléversez le graphique de l'un des
        deux essais pour CHAQUE acide (2 graphiques) en utilisant le lien ci-dessous. Vos graphiques
        doivent être clairement titrés et au format PDF.
      </p>

      <p class="mb-5">
        <v-file-input v-model="attachments" multiple />
      </p>

      <!-- Calculations -->
      <h3 class="pt-4 pb-3">Calculations</h3>

      <v-textarea
        v-model="inputs.calculations_table_caption"
        label="Légende"
        outlined
        rows="1"
        class="mb-n3"
        placeholder="Rédigez une légende pour le tableau des valeurs calculées ici"
      />
      <v-simple-table>
        <thead>
          <tr>
            <td>&nbsp;</td>
            <td style="font-weight: bold; text-align: center">Unités</td>
            <td style="font-weight: bold; text-align: center">Essai 1</td>
            <td style="font-weight: bold; text-align: center">Essai 1</td>
            <td style="font-weight: bold; text-align: center">Essai 2</td>
            <td style="font-weight: bold; text-align: center">Essai 2</td>
          </tr>
        </thead>
        <tr
          v-for="{label, key, units: rowUnits, type: inputType} in calculationTableRows"
          :key="key"
        >
          <th style="text-align: left">
            <stemble-latex :content="label" />
          </th>
          <td>
            <v-select
              v-if="!!rowUnits"
              v-model="inputs[`${key}_units`]"
              :items="units"
              item-value="key"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex :content="item.label" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.label" />
              </template>
            </v-select>
            <span v-else>&nbsp;</span>
          </td>
          <template v-for="i in 2">
            <td v-for="j in 2" :key="`known-${key}-${i}-${j}`">
              <v-textarea
                v-if="inputType === 'string'"
                v-model="inputs[`trial${i}_${j % 2 ? 'hcl' : 'hno3'}_${key}`]"
                outlined
                solo
                rows="3"
              />
              <calculation-input
                v-else
                v-model="inputs[`trial${i}_${j % 2 ? 'hcl' : 'hno3'}_${key}`]"
                class="centered-input"
              />
            </td>
          </template>
        </tr>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'OttawaLab1ReportTask6Data',
  components: {StembleLatex, CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    const inputs = {};

    const dataTableRows = [
      {label: 'Volume du base', key: 'volume_base', units: true},
      {label: 'Concentration du base', key: 'concentration_base', units: true},
      {label: 'Masse du calorimètre', key: 'mass_calorimeter', units: true},
      {label: 'Masse (calorimètre + base)', key: 'mass_calorimeter_and_base', units: true},
      {label: "Température du base et l'acide", key: 'initial_temp_base_acid', units: true},
      {label: "Volume de l'acide", key: 'volume_acid', units: true},
      {label: "Concentration de l'acide", key: 'concentration_acid', units: true},
      {label: 'Température finale du mélange', key: 'final_temp_mixture', units: true},
      {
        label: 'Masse finale (calorimètre + solution)',
        key: 'mass_calorimeter_and_contents',
        units: true,
      },
    ];

    const calculationTableRows = [
      {label: '$m_{soln}$', key: 'mass_solution', units: true},
      {label: '$\\Delta_{rxn} T$', key: 'delta_temp_reaction', units: true},
      {label: '$c_{soln}$', key: 'specific_heat_solution', units: true},
      {label: '$n_{acide}$', key: 'moles_acid', units: true},
      {label: '$n_{base}$', key: 'moles_base', units: true},
      {label: '$n_{rxn}$', key: 'moles_rxn', units: true},
      {
        label: '$\\Delta_N H^\\omicron$ (expérimentale)',
        key: 'enthalpy_neutralization',
        units: true,
      },
      {
        label: '$\\Delta_N H^\\omicron$ (littérature)',
        key: 'literature_enthalpy_neutralization',
        units: true,
      },
      {
        label: 'Référence pour $\\Delta_N H^\\omicron$',
        key: 'reference_literature_enthalpy_neutralization',
        units: false,
        type: 'string',
      },
      {label: "Pourcentage d'erreur", key: 'percent_error_enthalpy', units: true},
    ];

    for (const {key, units} of dataTableRows) {
      if (units) {
        inputs[`${key}_units`] = null;
      }
    }

    for (const {key, units} of calculationTableRows) {
      if (units) {
        inputs[`${key}_units`] = null;
      }
    }

    for (let trial = 1; trial <= 2; trial++) {
      for (const compound of ['hcl', 'hno3']) {
        for (const {key} of dataTableRows) {
          inputs[`trial${trial}_${compound}_${key}`] = null;
        }

        for (const {key} of calculationTableRows) {
          inputs[`trial${trial}_${compound}_${key}`] = null;
        }
      }
    }

    return {
      inputs: {
        data_table_caption: null,
        calculations_table_caption: null,
        ...inputs,
      },
      attachments: [],
      units: [
        {key: 'g', label: '$\\text{g}$'},
        {key: 'C', label: '$^{\\omicron}\\text{C}$'},
        {key: 'mL', label: '$\\text{mL}$'},
        {key: 'M', label: '$\\text{M}$'},
        {key: 'kJPerMol', label: '$\\text{kJ} \\cdot \\text{mol}^{-1}$'},
        {key: 'mol', label: '$\\text{mol}$'},
        {key: 'gPerMol', label: '$\\text{g} \\cdot \\text{mol}^{-1}$'},
        {
          key: 'JGC',
          label: '$\\text{J} \\cdot \\text{g}^{-1} \\cdot {^{\\omicron}\\text{C}}^{-1}$',
        },
        {
          key: 'percent',
          label: '$\\%$',
        },
      ],
      dataTableRows,
      calculationTableRows,
    };
  },
};
</script>
